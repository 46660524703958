<script setup>
  import RankingFansTop3Avatar from '@/components/Ranking/RankingFans/RankingFansTop3/RankingFansTop3Avatar.vue'
  import RankingFansTop3Body from '@/components/Ranking/RankingFans/RankingFansTop3/RankingFansTop3Body.vue'
  import rankingState from '@/components/Ranking/Store/ranking-state.js'

  const {
    topUsers
  } = rankingState
</script>

<template>
  <div class="flex items-end gap-x-4">
    <div v-if="topUsers[1]" class="flex flex-col items-center">
      <RankingFansTop3Avatar
        :rank="2"
        :user="topUsers[1]"
        class="mx-1 w-18 h-18 bg-gradient-to-r from-[#ffbb00] to-[#f29435]"/>

      <RankingFansTop3Body
        :user="topUsers[1]"
        :rank="2"/>
    </div>

    <div class="flex flex-col items-center">
      <RankingFansTop3Avatar
        :rank="1"
        :user="topUsers[0]"
        class="mx-1 w-24 h-24 bg-gradient-to-r from-[#ff5d7d] to-[#f234ac]"/>

      <RankingFansTop3Body
        :user="topUsers[0]"
        :rank="2"/>
    </div>

    <div v-if="topUsers[2]" class="flex flex-col items-center">
      <RankingFansTop3Avatar
        :rank="3"
        :user="topUsers[2]"
        class="mx-1 w-18 h-18 bg-gradient-to-r from-[#a9d6db] to-[#85a3a7]"/>

      <RankingFansTop3Body
        :user="topUsers[2]"
        :rank="2"/>
    </div>
  </div>
</template>