<script setup>
  import { computed } from 'vue'
  import AppAvatar from '@/components/App/AppAvatar.vue'
  import topCreator1png from '@/components/Ranking/Assets/top-creator-1.png'
  import topCreator2png from '@/components/Ranking/Assets/top-creator-2.png'
  import topCreator3png from '@/components/Ranking/Assets/top-creator-3.png'
  import { clientEventTrack } from '@/helpers/methods.js'

  const props = defineProps({
    rank: {
      type: Number,
      default: 0,
      required: true
    },
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    }
  })

  const badgeImage = computed(() => {
    switch (props.rank) {
      case 1:
        return topCreator1png
      case 2:
        return topCreator2png
      case 3:
        return topCreator3png
      default:
        return null
    }
  })
</script>

<template>
  <div class="p-0.5 rounded-full relative">
    <router-link
      :to="`/@${user.username}`"
      @click="clientEventTrack({ typeName: 'ranking__profile_clicked', userId: user.userId })">
      <AppAvatar
        class="w-full h-full"
        :user="user"/>
    </router-link>

    <img
      v-if="badgeImage"
      alt="Winner badge"
      class="absolute left-0 right-0 mx-auto"
      :src="badgeImage"
      :class="{
        'w-8 -top-5' : rank === 2 || rank === 3,
        'w-10 -top-7' : rank === 1,
      }">
  </div>
</template>