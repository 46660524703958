<script setup>
  import RatingsHeader from '@/components/Ranking/RankingHeader.vue'
  import RatingsTabs from '@/components/Ranking/RankingTabs.vue'
  import RatingsTimeframes from '@/components/Ranking/RankingTimeframes.vue'
  import RatingsFans from '@/components/Ranking/RankingFans.vue'
  import RatingsCreators from '@/components/Ranking/RankingCreators.vue'
  import ErrorMessage from '@/components/Functional/ErrorMessage.vue'
  import app from '@/main.js'
  import { computed, watch, ref, provide, onBeforeMount, nextTick } from 'vue'
  import { useTimeout } from '@vueuse/core'
  import RatingsLoading from '@/components/Ranking/Common/RankingLoading.vue'
  import RatingsNothingToSee from '@/components/Ranking/Common/RankingNothingToSee.vue'
  import { isArray } from 'radash'
  import ratingsState from '@/components/Ranking/Store/ranking-state.js'
  import { useRoute, useRouter } from 'vue-router'
  import Navbar from '@/components/Navbar.vue'
  import { preloadImage } from '@/helpers/app-helpers.js'
  import creatorTop1Png from '@/components/Ranking/Assets/creators-badges/creator-top-1.png'
  import creatorTop2Png from '@/components/Ranking/Assets/creators-badges/creator-top-2.png'
  import creatorTop3Png from '@/components/Ranking/Assets/creators-badges/creator-top-3.png'
  import fanTop1Png from '@/components/Ranking/Assets/fans-badges/fans-top-1.png'
  import fanTop2Png from '@/components/Ranking/Assets/fans-badges/fans-top-2.png'
  import fanTop3Png from '@/components/Ranking/Assets/fans-badges/fans-top-3.png'
  import { useAuthStore } from '@/stores/auth.js'

  const authStore = useAuthStore()

  const {
    activeTab,
    activeTimeframe,
    topUsers
  } = ratingsState

  const route = useRoute()
  const router = useRouter()

  const isActiveTabHasTimeframes = computed(() => {
    if (!authStore.authOptions?.rankings) {
      return null
    }

    return authStore.authOptions.rankings.availableRankingIds
      .filter(item => item.startsWith(`${activeTab.value}_`)).length
  })

  onBeforeMount(async () => {
    if (!authStore.authOptions.rankings) {
      router.push('/')
      return
    }

    const tab = route.query.tab

    preloadImage(creatorTop1Png)
    preloadImage(creatorTop2Png)
    preloadImage(creatorTop3Png)

    preloadImage(fanTop1Png)
    preloadImage(fanTop2Png)
    preloadImage(fanTop3Png)

    await nextTick()

    if (tab) {
      activeTab.value = tab.split('_')[0]
      activeTimeframe.value = tab.split('_')[1]
    }
  })

  const errorMessage = ref('')
  const loading = ref(false)
  const { ready, start } = useTimeout(500, { controls: true })

  const isLoading = computed(() => {
    return loading.value || !ready.value
  })

  const activeTabWithTimeframe = computed(() => {
    return `${activeTab.value}_${activeTimeframe.value}`
  })

  async function rankingGetTopUsers (tabId) {
    loading.value = true
    start()

    try {
      const { error, data } = await app.wsp.sendRequest({
        data: {
          id: tabId
        },
        method: 'ranking.getTopUsers'
      })

      if (error) {
        errorMessage.value = error?.message ? error.message : 'Unknown error'
        return
      }

      if (isArray(data.topUsers)) {
        topUsers.value = data.topUsers
      }
    } catch (error) {
      errorMessage.value = error?.message ? error.message : 'Unknown error'
    } finally {
      loading.value = false
    }
  }

  watch(activeTabWithTimeframe, async (tabId) => {
    if (!authStore.authOptions?.rankings || !isActiveTabHasTimeframes.value) {
      return
    }

    rankingGetTopUsers(tabId)
  }, { immediate: true })

  provide('isLoading', isLoading)
  provide('activeTabWithTimeframe', activeTabWithTimeframe)
</script>

<template>
  <div class="max-w-md mx-auto px-4 mt-6 pb-20 flex flex-col items-center">
    <RatingsHeader class="mb-4 w-full"/>

    <RatingsTabs class="mb-5"/>

    <RatingsTimeframes class="mb-12"/>

    <RatingsLoading
      v-if="isLoading"/>

    <RatingsNothingToSee v-else-if="!topUsers.length || !isActiveTabHasTimeframes"/>

    <ErrorMessage
      v-else-if="errorMessage"
      :message="errorMessage"/>

    <RatingsCreators v-else-if="activeTab === 'creators'"/>

    <RatingsFans v-else/>

    <Navbar/>
  </div>
</template>