<script setup>
  import rankingState from '@/components/Ranking/Store/ranking-state.js'
  import { inject, computed } from 'vue'
  import { useAuthStore } from '@/stores/auth.js'

  const authStore = useAuthStore()

  const {
    activeTimeframe,
    activeTab
  } = rankingState

  const timeframes = computed(() => {
    if (!authStore.authOptions?.rankings) {
      return null
    }

    return authStore.authOptions.rankings.availableRankingIds
      .filter(item => item.startsWith(`${activeTab.value}_`))
      .map(item => item.split('_')[1])
  })

  const isLoading = inject('isLoading')

  function selectTimeframe (timeframe) {
    if (isLoading.value) {
      return
    }

    activeTimeframe.value = timeframe
  }
</script>

<template>
  <div
    v-if="timeframes"
    class="flex items-center gap-2">
    <button
      v-for="item in timeframes"
      :key="item"
      type="button"
      :class="[activeTimeframe === item ? 'border-brand bg-brand text-white' : 'border-gray-400 text-gray-500']"
      class="min-w-28 p-1 border-2 rounded-full text-sm"
      @click="selectTimeframe(item)">
      <span v-if="item === '1d'">
        {{ $t('common.daily') }}
      </span>

      <span v-else-if="item === '7d'">
        {{ $t('common.weekly') }}
      </span>

      <span v-else-if="item === '30d'">
        {{ $t('common.monthly') }}
      </span>
    </button>
  </div>
</template>