<script setup>
  import AppAvatar from '@/components/App/AppAvatar.vue'
  import interests from '@/data/interests.json'
  import RankingBadge from '@/components/Ranking/Common/RankingBadge.vue'

  defineProps({
    index: {
      type: Number,
      default: 4,
      required: true
    },
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    }
  })

  function getInterestWithEmoji (interest) {
    return interests.find(item => {
      return item.toLowerCase().includes(interest.toLowerCase())
    }).split(' ')[0]
  }
</script>

<template>
  <div class="flex items-center">
    <span class="text-sm text-gray-400 p-2">
      {{ index + 4 }}
    </span>

    <div class="flex items-center gap-2 overflow-hidden max-w-[90%]">
      <AppAvatar class="w-12 h-12 flex-shrink-0" :user="user"/>

      <div class="flex flex-col gap-1 min-w-0">
        <div class="flex items-center gap-1">
          <span class="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
            {{ user.name ? user.name : '' }}
          </span>

          <RankingBadge
            v-if="user.rankingPositions"
            class="my-2.5"
            :is-clickable="false"
            :ranking-positions="user.rankingPositions"/>
        </div>

        <div v-if="user.interests" class="flex items-center gap-2 flex-wrap text-[18px]">
          <span
            v-for="item in user.interests.split(',').slice(0, 7)"
            :key="item"
            :title="item">
            {{ getInterestWithEmoji(item) }}
          </span>
        </div>

        <span
          v-else
          class="text-gray-400">–</span>
      </div>
    </div>
  </div>
</template>