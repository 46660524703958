<template>
  <teleport to="body">
    <div class="fixed bottom-0 left-0 right-0 z-30">
      <div
        style="box-shadow: 0 8px 34px rgba(0, 0, 0, 0.15);"
        :class="[isWebView ? 'pb-4' : 'pb-2']"
        class="max-w-md mx-auto bg-white flex items-center justify-around rounded-t-2xl px-3 pt-3">
        <!-- Creators / Members -->
        <router-link
          v-if="isUserFeedEnabled"
          active-class="creators-active-link"
          :class="[$route.path === '/creators' ? 'text-gray-900 stroke-gray-900' : 'text-gray-400 stroke-gray-400']"
          class="no-underline hover:text-gray-900  hover:stroke-gray-900"
          to="/creators">
          <div class="flex flex-col items-center">
            <span class="mb-1">
              <InlineSvg
                class="w-5 h-5"
                :src="require('@/assets/svg/navbar/crown.svg')"/>
            </span>

            <span class="text-xs">
              {{ isDatingEnabled ? $t('common.dating') : $t('common.creators') }}
            </span>
          </div>
        </router-link>

        <!-- Home / Moments -->
        <router-link
          class="no-underline hover:text-gray-900"
          :class="[$route.path === '/' ? 'text-gray-900' : 'text-gray-400']"
          to="/">
          <div class="flex flex-col items-center">
            <InlineSvg
              v-if="isUserFeedEnabled"
              class="inline-block w-5 h-5 mb-1"
              :src="require('@/assets/svg/navbar/sparkles.svg')"/>

            <InlineSvg
              v-else
              class="inline-block mb-1"
              :src="require('@/assets/svg/navbar/home.svg')"/>

            <span class="text-xs">{{ isUserFeedEnabled ? $t('common.moments') : $t('nav.menu.home') }}</span>
          </div>
        </router-link>

        <!-- Events  -->
        <router-link
          v-if="authOptions?.rankings"
          class="no-underline hover:text-gray-900 cursor-pointer"
          :to="isAuthenticated ? '/ranking' : ''"
          :class="[$route.name === 'Ranking' ? 'text-gray-900' : 'text-gray-400']"
          @click="openSignup();handleClick('Ranking')">
          <div class="flex flex-col items-center">
            <span class="inline-block">
              <InlineSvg
                class="inline-block w-5 h-5 mb-1"
                :src="require('@/assets/svg/navbar/ratings-star.svg')"/>
            </span>

            <span class="text-xs">
              {{ $t('ranking') }}
            </span>
          </div>
        </router-link>

        <!-- Events  -->
        <router-link
          v-else-if="options.eventsEnabled"
          class="no-underline hover:text-gray-900 cursor-pointer"
          :to="isAuthenticated ? '/events' : ''"
          :class="[$route.name === 'Events' ? 'text-gray-900' : 'text-gray-400']"
          @click="openSignup">
          <div class="flex flex-col items-center">
            <span class="inline-block relative">
              <InlineSvg
                class="inline-block w-5 h-5 mb-1"
                :src="require('@/assets/svg/navbar/bell.svg')"/>

              <span
                v-if="invites.length || thereAreEvents"
                class="absolute bottom-0.5 bg-primary -right-1 flex items-center justify-center h-2.5 w-2.5 rounded-full ring-1 ring-white text-[9px] text-white leading-3">
                <!--{{ invites.length > 9 ? '9+' : invites.length }}-->
              </span>
            </span>

            <span class="text-xs">
              {{ $t('events.title') }}
            </span>
          </div>
        </router-link>

        <!-- Discover -->
        <router-link
          v-else
          class="no-underline  hover:text-gray-900 cursor-pointer"
          :class="[$route.path === '/discover' ? 'text-gray-900' : 'text-gray-400']"
          :to="isAuthenticated ? '/discover' : ''"
          @click="openSignup">
          <div class="flex flex-col items-center">
            <InlineSvg
              class="inline-block mb-1"
              :src="require('@/assets/svg/navbar/discover.svg')"/>

            <span class="text-xs">{{ $t('nav.menu.discover') }}</span>
          </div>
        </router-link>

        <!-- Messages  -->
        <router-link
          class="no-underline hover:text-gray-900 cursor-pointer"
          :to="isAuthenticated ? '/messages' : ''"
          :class="[$route.path === '/messages' ? 'text-gray-900' : 'text-gray-400']"
          @click="openSignup">
          <div class="flex flex-col items-center">
            <span class="inline-block relative">
              <InlineSvg
                class="inline-block mb-1 w-5 h-5"
                :src="require('@/assets/svg/navbar/message-minus.svg')"/>

              <span
                v-if="isNewMessages"
                class="absolute bottom-0.5 bg-primary -right-1 flex items-center justify-center h-3 w-3 rounded-full ring-1 ring-white text-[9px] text-white leading-3">
                {{ newMessagesCount }}
              </span>
            </span>

            <span class="text-xs">{{ $t('nav.menu.messages') }}</span>
          </div>
        </router-link>

        <!-- Me -->
        <router-link
          class="no-underline  hover:text-gray-900 cursor-pointer"
          :class="[$route.path === '/me' ? 'text-gray-900' : 'text-gray-400']"
          :to="isAuthenticated ? '/me' : ''"
          @click="openSignup">
          <div class="flex flex-col items-center">
            <InlineSvg
              class="inline-block mb-1 w-5 h-5"
              :src="require('@/assets/svg/navbar/user.svg')"/>

            <span class="text-xs capitalize">{{ isDatingEnabled ? $t('common.profile') : $t('nav.menu.me') }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </teleport>
</template>

<script>

import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useEventsStore } from '@/components/Events/Store/events'
import { useDialogsStore } from '@/stores/dialogs'
import helpers from '@/helpers'
import { clientEventTrack } from '@/helpers/methods.js'

export default {
  name: 'Navbar',
  components: {},
  data () {
    return {
      isStandalone: window.matchMedia('(display-mode: standalone)').matches,
      isWebView: helpers.isWebView()
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ]),
    ...mapState(useDialogsStore, [
      'invites'
    ]),
    ...mapState(useEventsStore, [
      'thereAreEvents'
    ]),
    ...mapState(useUserStore, [
      'unreadMessagesCounter',
      'premiumSupportCount'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isTokenExist',
      'isUserFeedEnabled',
      'options',
      'authOptions'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible'
    ]),
    isNewMessages () {
      return this.unreadMessagesCounter || this.premiumSupportCount
    },
    newMessagesCount () {
      const unreadMessagesCounter = this.unreadMessagesCounter ? this.unreadMessagesCounter : 0
      const premiumSupportCount = this.premiumSupportCount ? this.premiumSupportCount : 0

      const count = unreadMessagesCounter + premiumSupportCount
      return count > 9 ? '9+' : count
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    openSignup () {
      if (!this.isTokenExist) {
        helpers.showSignUp()
      }
    },
    handleClick (pageName) {
      if (pageName === 'Ranking' && this.isAuthenticated) {
        clientEventTrack({
          typeName: 'navbar__rankings_clicked'
        })
      }
    }
  }
}
</script>

<style scoped>
  .creators-active-link {
    /*@apply text-gray-900 stroke-gray-900;*/
  }
</style>
