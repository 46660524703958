import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { pinia } from './stores'
import Notifications from '@kyvg/vue3-notification'
import InlineSvg from 'vue-inline-svg'
import VueSmoothScroll from 'vue3-smooth-scroll'
// https://stackoverflow.com/a/73674885
// import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
import { createI18n } from 'vue-i18n'
import i18nHelpers from '@/i18nHelpers'
import VRuntimeTemplate from 'vue3-runtime-template'
import Modal from '@/components/Functional/Modal'
import ClipboardJS from 'clipboard'
// Plugins
import websocket from './plugins/websocket'
import axiosInterceptors from '@/plugins/axiosInterceptors'
import appHeight from '@/plugins/appHeight'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createMetaManager } from 'vue-meta'
import mixins from '@/plugins/mixins'
import Vue3TouchEvents from 'vue3-touch-events'
import invitesPlugin from './plugins/invitesPlugin'
import loggerPlugin from './plugins/logger'
import logsSaverPlugin from './plugins/logsSaverPlugin'
import dayjsPlugin from './plugins/dayjsPlugin'
import yupPlugin from './plugins/yupPlugin'

// Styles
import './assets/styles/index.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Directives
import clickOutside from '@/directives/click-outside'
import hidePlaceholder from '@/directives/hide-placeholder'
import resize from '@/directives/resize'

// Error handler
import errorHandler from '@/errorHandler'

// eslint-disable-next-line no-new
new ClipboardJS('.js-clipboard')

const app = createApp(App)


app.config.errorHandler = errorHandler
app.use(router)
app.use(pinia)

const startingLocale = i18nHelpers.getStartingLocale()
i18nHelpers.setDocumentLang(startingLocale)

const i18n = createI18n({
  // legacy: false,
  locale: startingLocale,
  fallbackLocale: 'en',
  messages: i18nHelpers.loadLocaleMessages(),
  silentTranslationWarn: true,
  silentFallbackWarn: true
})

app.use(i18n)
app.$i18n = i18n.global

app.use(websocket, {
  socketPath: process.env.VUE_APP_SOCKET_PATH
})

app.use(logsSaverPlugin)

if (process.env.VUE_APP_LOGGER_URL) {
  app.use(loggerPlugin)
}

app.use(Notifications)
app.use(createMetaManager())
app.use(VueAxios, axios)
app.use(axiosInterceptors)
app.use(appHeight)
app.use(mixins)
app.use(dayjsPlugin)
app.use(Vue3TouchEvents)
app.use(invitesPlugin)
app.use(yupPlugin)
app.use(VueSmoothScroll)

app.component('InlineSvg', InlineSvg)
app.component('VRuntimeTemplate', VRuntimeTemplate)
app.component('Modal', Modal)

app.directive('click-outside', clickOutside)
app.directive('hide-placeholder', hidePlaceholder)
app.directive('resize', resize)

window.one2fan = window.one2fan || {}

if (process.env.NODE_ENV === 'development') {
  window.one2fan.origin = 'https://one2fan.com'
} else {
  window.one2fan.origin = window.location.origin
}

app.config.globalProperties.$window = window

app.mount('#app')

export default app
