<script setup>
  import rankingState from '@/components/Ranking/Store/ranking-state.js'
  import { inject } from 'vue'

  const {
    activeTab
  } = rankingState

  const isLoading = inject('isLoading')

  function selectTab (tab) {
    if (isLoading.value) {
      return
    }

    activeTab.value = tab
  }
</script>

<template>
  <div class="flex items-center">
    <button
      type="button"
      :class="[activeTab === 'creators' ? 'border-brand text-brand font-bold' : 'border-transparent']"
      class="min-w-28 px-4 py-3 border-b-2"
      @click="selectTab('creators')">
      {{ $t('common.creators') }}
    </button>

    <button
      type="button"
      :class="[activeTab === 'consumers' ? 'border-brand text-brand font-bold' : 'border-transparent']"
      class="min-w-28 px-4 py-3 border-b-2"
      @click="selectTab('consumers')">
      {{ $t('common.fans') }}
    </button>
  </div>
</template>