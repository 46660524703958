export default {
  install: (app) => {
    app.mixin({
      methods: {
        // Solution: https://stackoverflow.com/a/14994860
        countNumberFormat: (num) => {
          if (!num) return 0

          if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
          }
          if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
          }
          if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
          }
          return num.toFixed()
        }
      }
    })
  }
}
