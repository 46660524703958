<script setup>
  import creatorTop1Png from '@/components/Ranking/Assets/creators-badges/creator-top-1.png'
  import creatorTop2Png from '@/components/Ranking/Assets/creators-badges/creator-top-2.png'
  import creatorTop3Png from '@/components/Ranking/Assets/creators-badges/creator-top-3.png'
  import creatorTop4Png from '@/components/Ranking/Assets/creators-badges/creator-top-4.png'
  import creatorTop5Png from '@/components/Ranking/Assets/creators-badges/creator-top-5.png'
  import creatorTop6Png from '@/components/Ranking/Assets/creators-badges/creator-top-6.png'
  import { computed, inject } from 'vue'

  const props = defineProps({
    rank: {
      type: Number,
      default: 0,
      required: true
    },
    timeframe: {
      type: String,
      default: '30d',
      required: true
    }
  })

  const isClickable = inject('isClickable')

  const component = computed(() => {
    return isClickable ? 'router-link' : 'span'
  })

  const timeframeToHuman = new Map([
    ['1d', 'Daily'],
    ['7d', 'Weekly'],
    ['30d', 'Monthly']
  ])

  const humanTimeframe = computed(() => {
    return timeframeToHuman.get(props.timeframe)
  })

  const badgeSetting = computed(() => {
    const rank = props.rank

    if (rank === 1) {
      return {
        image: creatorTop1Png,
        outerClass: 'bg-gradient-to-r from-[#F234AC] to-[#FF5C7C]',
        innerClass: 'bg-gradient-to-r from-[#FF5C7C] to-[#F234AC]',
        top: 1
      }
    } else if (rank === 2) {
      return {
        image: creatorTop2Png,
        outerClass: 'bg-gradient-to-r from-[#F29334] to-[#FFBB00]',
        innerClass: 'bg-gradient-to-r from-[#FFBB00] to-[#F29334]',
        top: 2
      }
    } else if (rank === 3) {
      return {
        image: creatorTop3Png,
        outerClass: 'bg-gradient-to-r from-[#85A4A7] to-[#A9D6DB]',
        innerClass: 'bg-gradient-to-r from-[#A9D6DB] to-[#85A4A7]',
        top: 3
      }
    } else if (rank >= 4 && rank <= 10) {
      return {
        image: creatorTop4Png,
        outerClass: 'bg-gradient-to-r from-[#E26A00] to-[#FFAE5C]',
        innerClass: 'bg-gradient-to-r from-[#FFAE5C] to-[#E26A00]',
        top: 10
      }
    } else if (rank >= 11 && rank <= 20) {
      return {
        image: creatorTop5Png,
        outerClass: 'bg-gradient-to-r from-[#355289] to-[#588ABA]',
        innerClass: 'bg-gradient-to-r from-[#588ABA] to-[#355289]',
        top: 20
      }
    } else if (rank >= 21 && rank <= 50) {
      return {
        image: creatorTop6Png,
        outerClass: 'bg-gradient-to-r from-[#F23459] to-[#FF6200]',
        innerClass: 'bg-gradient-to-r from-[#FF6200] to-[#F23459]',
        top: 50
      }
    } else if (rank >= 51 && rank <= 100) {
      return {
        image: null,
        outerClass: 'bg-gradient-to-r from-[#F15CFF] to-[#F234AC]',
        innerClass: 'bg-gradient-to-r from-[#F234AC] to-[#F15CFF]',
        shapeClass: 'bg-[#FFD0DF]',
        svgClass: 'text-[#FF00DD]',
        top: 100
      }
    } else if (rank >= 101 && rank <= 500) {
      return {
        image: null,
        outerClass: 'bg-gradient-to-r from-[#F15CFF] to-[#F15CFF]',
        innerClass: 'bg-gradient-to-r from-[#E662FF] to-[#7B91FF]',
        shapeClass: 'bg-[#D0E5FF]',
        svgClass: 'text-[#E662FF]',
        top: 500
      }
    } else if (rank >= 501 && rank <= 1000) {
      return {
        image: null,
        outerClass: 'bg-gradient-to-r from-[#7B91FF] to-[#F15CFF]',
        innerClass: 'bg-gradient-to-r from-[#7B91FF] to-[#00CAB2]',
        shapeClass: 'bg-[#ffffff]',
        svgClass: 'text-[#7B93FE]',
        top: 1000
      }
    } else {
      return null
    }
  })
</script>

<template>
  <component
    :is="component"
    v-if="badgeSetting && humanTimeframe"
    :to="{ path: '/ranking', query: { tab: `creators_${timeframe}` }}"
    :class="badgeSetting.outerClass"
    class="p-px inline-flex rounded-full whitespace-nowrap">
    <span
      :class="[badgeSetting.innerClass, [(rank >= 51 && rank <= 1000) ? 'pl-3.5' : 'pl-6']]"
      class="relative inline-flex items-center rounded-full px-1.5 text-[9px] leading-3 font-bold text-white">
      <img
        v-if="badgeSetting.image"
        :src="badgeSetting.image"
        alt="Badge"
        class="w-5 absolute left-0.5">

      <span
        v-if="(rank >= 51 && rank <= 1000)"
        :class="badgeSetting.shapeClass"
        class="absolute left-px w-2.5 h-2.5 rounded-full flex items-center justify-center">
        <InlineSvg
          :class="badgeSetting.svgClass"
          :src="require('@/assets/fontawesome/solid/star.svg')"
          class="w-2 h-2 p-px"/>
      </span>

      {{ humanTimeframe }} TOP {{ badgeSetting.top }}
    </span>
  </component>
</template>