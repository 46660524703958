<script setup>
  import { inject } from 'vue'
  import InlineSvg from 'vue-inline-svg'
  import app from '@/main.js'

  const close = inject('close')
</script>

<template>
  <div
    class="rounded-xl bg-white p-6 shadow-lg w-full relative"
    @click.stop>
    <div class="mb-4 flex items-center gap-2 justify-between">
      <h3 class="text-2xl">
        {{ app.$i18n.t('common.methodology') }}
      </h3>

      <button
        class="text-gray-950 p-2 -mr-2"
        type="button"
        @click="close">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="w-3 h-3"/>
      </button>
    </div>

    <div class="mb-6 whitespace-pre-line">
      {{ app.$i18n.t('ranking.methodology_text') }}
    </div>

    <button
      class="w-full btn btn-secondary rounded"
      type="button"
      @click="close">
      {{ app.$i18n.t('common.close') }}
    </button>
  </div>
</template>