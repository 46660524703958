<script setup>
  import interests from '@/data/interests.json'

  defineProps({
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    },
    rank: {
      type: Number,
      default: 0,
      required: true
    }
  })

  function getInterestWithEmoji (interest) {
    return interests.find(item => {
      return item.toLowerCase().includes(interest.toLowerCase())
    }).split(' ')[0]
  }
</script>

<template>
  <section class="w-full min-w-0 text-center flex flex-col items-center">
    <h3
      :class="{
        'max-w-[5.25rem]' : rank === 2 || rank === 3,
        'max-w-[6.5rem]' : rank === 1,
      }"
      :title="user.name"
      class="mt-2 font-semibold text-center whitespace-nowrap overflow-hidden text-ellipsis text-sm">
      {{ user.name ? user.name.split(' ')[0] : '' }}
    </h3>

    <div 
      v-if="user.interests"
      class="mt-1 h-6 flex items-center justify-center flex-wrap text-sm gap-x-1.5 max-w-[80px]">
      <span
        v-for="item in user.interests.split(',').slice(0, 4)"
        :key="item"
        :title="item">
        {{ getInterestWithEmoji(item) }}
      </span>
    </div>

    <div
      v-else
      class="mt-1 h-6 text-gray-400">
      –
    </div>
  </section>
</template>