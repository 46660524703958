<script setup>
  import RatingsCreatorsBadge from '@/components/Ranking/Common/RankingBadges/RankingCreatorsBadge.vue'
  import RatingsFansBadge from '@/components/Ranking/Common/RankingBadges/RankingFansBadge.vue'
  import RatingsNewbieBadge from '@/components/Ranking/Common/RankingBadges/RankingNewbieBadge.vue'
  import { useAuthStore } from '@/stores/auth.js'

  const authStore = useAuthStore()
</script>

<template>
  <div class="max-w-md mx-auto my-6 px-4">
    <div class="grid grid-cols-2">
      <div class="flex flex-col gap-5">
        <RatingsCreatorsBadge timeframe="1d" :rank="1" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="7d" :rank="2" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="3" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="4" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="11" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="21" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="51" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="101" class="max-w-max"/>
        <RatingsCreatorsBadge timeframe="30d" :rank="501" class="max-w-max"/>
      </div>

      <div class="flex flex-col gap-5 mb-5">
        <RatingsFansBadge timeframe="1d" :rank="1" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="2" class="max-w-max"/>
        <RatingsFansBadge timeframe="30d" :rank="3" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="4" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="11" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="21" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="51" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="101" class="max-w-max"/>
        <RatingsFansBadge timeframe="7d" :rank="501" class="max-w-max"/>
      </div>

      <RatingsNewbieBadge/>
    </div>

    <pre>{{ authStore.authOptions.rankings }}</pre>
  </div>
</template>