<script setup>
  import { computed } from 'vue'
  import AppAvatar from '@/components/App/AppAvatar.vue'
  import topFan1png from '@/components/Ranking/Assets/fans-badges/fans-top-1.png'
  import topFan2png from '@/components/Ranking/Assets/fans-badges/fans-top-2.png'
  import topFan3png from '@/components/Ranking/Assets/fans-badges/fans-top-3.png'

  const props = defineProps({
    rank: {
      type: Number,
      default: 0,
      required: true
    },
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    }
  })

  const badgeImage = computed(() => {
    switch (props.rank) {
      case 1:
        return topFan1png
      case 2:
        return topFan2png
      case 3:
        return topFan3png
      default:
        return null
    }
  })
</script>

<template>
  <div class="p-0.5 rounded-full relative">
    <AppAvatar
      class="w-full h-full"
      :user="user"/>

    <img
      v-if="badgeImage"
      alt="Winner badge"
      class="absolute -left-1 -bottom-1"
      :src="badgeImage"
      :class="{
        'w-8' : rank === 2 || rank === 3,
        'w-10' : rank === 1,
      }">
  </div>
</template>