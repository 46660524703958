<script setup>
  import RankingListFansItem from '@/components/Ranking/RankingFans/RankingFansList/RankingListFansItem.vue'
  import rankingState from '@/components/Ranking/Store/ranking-state.js'
  import { computed } from 'vue'
  import { isArray } from 'radash'

  const {
    topUsers
  } = rankingState

  const topUsersCut = computed(() => {
    if (isArray(topUsers.value) && topUsers.value.length > 3) {
      return topUsers.value.slice(3)
    }

    return null
  })
</script>

<template>
  <div v-if="topUsersCut" class="space-y-6 w-full px-2.5 pb-22">
    <RankingListFansItem
      v-for="(item, index) in topUsersCut"
      :key="index"
      :user="item"
      :index="index"/>
  </div>
</template>