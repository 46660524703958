<script setup>
  import AppAvatar from '@/components/App/AppAvatar.vue'
  import interests from '@/data/interests.json'
  import RankingBadge from '@/components/Ranking/Common/RankingBadge.vue'
  import { useUserStore } from '@/stores/user.js'
  import { storeToRefs } from 'pinia'
  import { computed, inject } from 'vue'
  import { isObject } from 'radash'

  const activeTabWithTimeframe = inject('activeTabWithTimeframe')
  const userStore = useUserStore()

  const {
    user
  } = storeToRefs(userStore)

  const currentRank = computed(() => {
    if (isObject(user.value.rankingPositions) && activeTabWithTimeframe.value) {
      return user.value.rankingPositions[activeTabWithTimeframe.value]?.rank
    } else {
      return null
    }
  })

  function getInterestWithEmoji (interest) {
    return interests.find(item => {
      return item.toLowerCase().includes(interest.toLowerCase())
    }).split(' ')[0]
  }
</script>

<template>
  <div class="max-w-md mx-auto">
    <div class="mx-4 flex items-center p-2.5 rounded-lg bg-white shadow">
      <span class="text-sm text-gray-400 p-2">
        {{ currentRank ? currentRank : '...' }}
      </span>

      <div class="flex items-center gap-2 overflow-hidden max-w-[90%]">
        <AppAvatar class="w-12 h-12" :user="user"/>

        <div class="flex flex-col gap-1 min-w-0">
          <div class="flex items-center gap-1">
            <span class="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {{ user.name ? user.name : '' }}
            </span>

            <RankingBadge
              v-if="user.rankingPositions"
              class="my-2.5"
              :is-clickable="false"
              :is-newbie="user.isNewbie"
              :ranking-positions="user.rankingPositions"/>
          </div>

          <div v-if="user.interests" class="flex items-center gap-2 text-[18px]">
            <span
              v-for="item in user.interests.split(',').slice(0, 7)"
              :key="item"
              :title="item">
              {{ getInterestWithEmoji(item) }}
            </span>
          </div>

          <span
            v-else
            class="text-gray-400">–</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .shadow {
    box-shadow: 0 0 16px 0 rgba(3, 7, 18, 0.10);
  }
</style>