<script setup>
  import AppBalance from '@/components/App/AppBalance.vue'
  import { createComponent } from '@/helpers/utils.js'
  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import RankingInfoModal from '@/components/Ranking/Common/RankingInfoModal.vue'

  function openInfoModal () {
    const modalProps = {
      wrapperClass: 'max-w-md m-auto w-full'
    }

    const rankingInfoModalProps = {}

    createComponent(ModalWindow, modalProps, RankingInfoModal, rankingInfoModalProps)
  }
</script>

<template>
  <header class="flex items-center justify-between gap-2">
    <div class="flex items-center gap-1.5">
      <h1>
        {{ $t('ranking') }}
      </h1>

      <button type="button" @click="openInfoModal">
        <InlineSvg
          class="w-5 h-5 text-gray-400"
          :src="require('@/assets/fontawesome/regular/circle-info.svg')"/>
      </button>
    </div>

    <AppBalance/>
  </header>
</template>