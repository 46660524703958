<template>
  <div
    :class="[hasChatNavigation ? 'min-h-[76px] h-[76px]' : 'min-h-[57px] h-[57px]']"
    class="chat-header px-4 py-2">
    <div class="flex items-center">
      <LinkBack
        path="/messages"
        class="pr-3 mr-1 text-gray-900"
        @click="checkFakeInvite"/>

      <div
        v-show="chatUser.name"
        class="flex flex-1">
        <router-link
          :to="premiumSupportAgentUserId.toString() === chatUser.userId ? `${$route.fullPath}` : `/@${chatUser.username}`"
          class="flex"
          @click="clientEventTrack({ typeName: 'chat__header_profile_clicked', userId: chatUser.userId })">
          <div class="relative">
            <Avatar
              class="h-8 w-8 mr-2"
              :show-status="true"
              :user-item="chatUser"/>
            <div
              v-if="options.prelandingId === 'apreland' && !isPremiumSupportUser"
              class="absolute bottom-0 -left-1 text-[9px] leading-[15px] rounded bg-primary w-max px-[3px] text-white font-bold">
              AI
            </div>
          </div>

          <div class="flex flex-col flex-1">
            <div class="flex items-center gap-1">
              <h3 class="text-sm leading-4 flex gap-1">
                <span class="whitespace-nowrap overflow-hidden text-ellipsis min-w-0 max-w-22">
                  {{ chatUser.name ? chatUser.name.split(' ')[0] : '' }}
                </span>

                <template v-if="(options.verificationEnabled && chatUser.tikTokFollowerCount && !isPremiumSupportUser) || 1">
                  <InlineSvg
                    :src="require('@/assets/svg/design/verified.svg')"
                    class="inline"/>
                </template>
              </h3>

              <RatingsBadge
                v-if="chatUser.rankingPositions"
                :user-id="chatUser.userId"
                :ranking-positions="chatUser.rankingPositions"/>
            </div>

            <a class="text-caption text-gray-600 leading-4">@{{ chatUser.username }}</a>
          </div>

          <span
            v-if="chatUser.deleted"
            title="User deleted"
            class="ml-3 flex-shrink-0 rounded-full w-8 h-8 bg-red-400 flex items-center justify-center">
            <InlineSvg
              :src="require('@/assets/svg/regular/trash.svg')"
              class="w-3.5 h-3.5 text-white mb-0.5"/>
          </span>
        </router-link>
      </div>

      <AppBalance v-if="options.chatCreditBalanceEnabled && !isPremiumSupportUser"/>

      <template v-if="!isPremiumSupportUser">
        <Dropdown
          v-if="isAuthenticated"
          :items="[
            { text: $t('creator.block.block_this_creator'), callback: blockUser },
            { text: $t('creator.block.report_this_creator'), callback: reportUser },
          ]">
          <!-- dots ... -->
          <button
            id="options-menu"
            type="button"
            class="rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 text-gray-900 hover:text-gray-500">
            <svg
              class="h-5 w-5"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.17993 10.5C6.17993 11.0304 5.96922 11.5391 5.59415 11.9142C5.21907 12.2893 4.71036 12.5 4.17993 12.5C3.6495 12.5 3.14079 12.2893 2.76572 11.9142C2.39065 11.5391 2.17993 11.0304 2.17993 10.5C2.17993 9.96957 2.39065 9.46086 2.76572 9.08579C3.14079 8.71071 3.6495 8.5 4.17993 8.5C4.71036 8.5 5.21907 8.71071 5.59415 9.08579C5.96922 9.46086 6.17993 9.96957 6.17993 10.5ZM12.1799 10.5C12.1799 11.0304 11.9692 11.5391 11.5941 11.9142C11.2191 12.2893 10.7104 12.5 10.1799 12.5C9.6495 12.5 9.14079 12.2893 8.76572 11.9142C8.39065 11.5391 8.17993 11.0304 8.17993 10.5C8.17993 9.96957 8.39065 9.46086 8.76572 9.08579C9.14079 8.71071 9.6495 8.5 10.1799 8.5C10.7104 8.5 11.2191 8.71071 11.5941 9.08579C11.9692 9.46086 12.1799 9.96957 12.1799 10.5ZM18.1799 10.5C18.1799 11.0304 17.9692 11.5391 17.5941 11.9142C17.2191 12.2893 16.7104 12.5 16.1799 12.5C15.6495 12.5 15.1408 12.2893 14.7657 11.9142C14.3906 11.5391 14.1799 11.0304 14.1799 10.5C14.1799 9.96957 14.3906 9.46086 14.7657 9.08579C15.1408 8.71071 15.6495 8.5 16.1799 8.5C16.7104 8.5 17.2191 8.71071 17.5941 9.08579C17.9692 9.46086 18.1799 9.96957 18.1799 10.5Z"/>
            </svg>
          </button>
        </Dropdown>

        <!-- dots ... -->
        <button
          v-else
          type="button"
          class="rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 text-gray-900 hover:text-gray-500"
          @click="showSignUp">
          <svg
            class="h-5 w-5"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.17993 10.5C6.17993 11.0304 5.96922 11.5391 5.59415 11.9142C5.21907 12.2893 4.71036 12.5 4.17993 12.5C3.6495 12.5 3.14079 12.2893 2.76572 11.9142C2.39065 11.5391 2.17993 11.0304 2.17993 10.5C2.17993 9.96957 2.39065 9.46086 2.76572 9.08579C3.14079 8.71071 3.6495 8.5 4.17993 8.5C4.71036 8.5 5.21907 8.71071 5.59415 9.08579C5.96922 9.46086 6.17993 9.96957 6.17993 10.5ZM12.1799 10.5C12.1799 11.0304 11.9692 11.5391 11.5941 11.9142C11.2191 12.2893 10.7104 12.5 10.1799 12.5C9.6495 12.5 9.14079 12.2893 8.76572 11.9142C8.39065 11.5391 8.17993 11.0304 8.17993 10.5C8.17993 9.96957 8.39065 9.46086 8.76572 9.08579C9.14079 8.71071 9.6495 8.5 10.1799 8.5C10.7104 8.5 11.2191 8.71071 11.5941 9.08579C11.9692 9.46086 12.1799 9.96957 12.1799 10.5ZM18.1799 10.5C18.1799 11.0304 17.9692 11.5391 17.5941 11.9142C17.2191 12.2893 16.7104 12.5 16.1799 12.5C15.6495 12.5 15.1408 12.2893 14.7657 11.9142C14.3906 11.5391 14.1799 11.0304 14.1799 10.5C14.1799 9.96957 14.3906 9.46086 14.7657 9.08579C15.1408 8.71071 15.6495 8.5 16.1799 8.5C16.7104 8.5 17.2191 8.71071 17.5941 9.08579C17.9692 9.46086 18.1799 9.96957 18.1799 10.5Z"/>
          </svg>
        </button>
      </template>
    </div>

    <ChatNavigation
      v-if="hasChatNavigation"/>

    <Modal
      v-slot="{ values, closeModal }"
      ref="blockModal"
      name="BlockUser">
      <BlockUserContent
        :heading="$t('creator.block.block_user')"
        :values="values"
        @close="closeModal()"/>
    </Modal>

    <Modal
      v-slot="{ values, closeModal }"
      ref="reportModal"
      name="ReportUser">
      <BlockUserContent
        :heading="$t('creator.block.report_user')"
        :values="values"
        type="report"
        @close="closeModal()"/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="notEnoughCreditsModal"
      name="NoCredits"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white p-5"
      wrapper-class=""
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <NoCreditsContent @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import LinkBack from '@/components/Functional/LinkBack'
import Dropdown from '@/components/Functional/Dropdown'
import Modal from '@/components/Functional/Modal'
import BlockUserContent from '@/components/ModalContents/BlockUserContent'
import { mapState, mapWritableState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import helpers from '@/helpers'
import { useDialogsStore } from '@/stores/dialogs'
import NoCreditsContent from '@/components/ModalContents/chat/NoCreditsContent.vue'
import { clientEventTrack } from '@/helpers/methods'
import ChatNavigation from '@/components/Chat/ChatMessages/ChatNavigation.vue'
import AppBalance from '@/components/App/AppBalance.vue'
import RatingsBadge from '@/components/Ranking/Common/RankingBadge.vue'

export default {
  name: 'ChatHeader',
  components: {
    LinkBack,
    Avatar,
    Dropdown,
    Modal,
    BlockUserContent,
    NoCreditsContent,
    ChatNavigation,
    AppBalance,
    RatingsBadge
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'balance',
      'features'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'options'
    ]),
    ...mapState(useChatStore, [
      'chatUser',
      'sortedMessages'
    ]),
    ...mapWritableState(useDialogsStore, [
      'fakeInvite'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    hasChatNavigation () {
      return this.chatUser && !this.isPremiumSupportUser && this.options.chatNavigationEnabled
    }
  },
  mounted () {
    this.checkFakeInvite()
  },
  methods: {
    clientEventTrack: clientEventTrack,
    blockUser () {
      if (!this.chatUser.userId) return

      this.$refs.blockModal.openModal({ userId: this.chatUser.userId })
    },
    reportUser () {
      if (!this.chatUser.userId) {
        return
      }

      this.$refs.reportModal.openModal({
        userId: this.chatUser.userId,
        username: this.chatUser.username
      })
    },
    showSignUp () {
      helpers.showSignUp()
    },
    checkFakeInvite () {
      if ((this.fakeInvite?.userId === this.chatUser.userId) && this.sortedMessages.length > 0) {
        this.fakeInvite.contact = true
      }
    }
  }
}
</script>

<style>
  .chat-header {
    @apply flex-grow-0 border-l border-r border-gray-100 w-full
  }
</style>
