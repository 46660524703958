<script setup>
  import RankingCreatorsTop3Avatar from '@/components/Ranking/RankingCreators/RankingCreatorsTop3/RankingCreatorsTop3Avatar.vue'
  import RankingCreatorsTop3Body from '@/components/Ranking/RankingCreators/RankingCreatorsTop3/RankingCreatorsTop3Body.vue'
  import rankingState from '@/components/Ranking/Store/ranking-state.js'

  const {
    topUsers
  } = rankingState
</script>

<template>
  <div class="flex items-end gap-x-4">
    <div v-if="topUsers[1]" class="flex flex-col items-center">
      <RankingCreatorsTop3Avatar
        :rank="2"
        :user="topUsers[1]"
        class="mx-1 w-18 h-18 bg-gradient-to-r from-[#e7e7e7] to-[#b7b7b7]"/>

      <RankingCreatorsTop3Body
        :user="topUsers[1]"
        :rank="2"/>
    </div>

    <div class="flex flex-col items-center">
      <RankingCreatorsTop3Avatar
        :rank="1"
        :user="topUsers[0]"
        class="mx-1 w-24 h-24 bg-gradient-to-r from-[#ffeaa7] to-[#ffd440]"/>

      <RankingCreatorsTop3Body
        :user="topUsers[0]"
        :rank="1"/>
    </div>

    <div v-if="topUsers[2]" class="flex flex-col items-center">
      <RankingCreatorsTop3Avatar
        :rank="3"
        :user="topUsers[2]"
        class="mx-1 w-18 h-18 bg-gradient-to-r from-[#f0af6e] to-[#d88532]"/>

      <RankingCreatorsTop3Body
        :user="topUsers[2]"
        :rank="3"/>
    </div>
  </div>
</template>