<script setup>
  import { computed, provide, inject } from 'vue'
  import { isObject } from 'radash'
  import RankingCreatorsBadge from '@/components/Ranking/Common/RankingBadges/RankingCreatorsBadge.vue'
  import RankingFansBadge from '@/components/Ranking/Common/RankingBadges/RankingFansBadge.vue'
  import RankingNewbieBadge from '@/components/Ranking/Common/RankingBadges/RankingNewbieBadge.vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { clientEventTrack } from '@/helpers/methods.js'

  const authStore = useAuthStore()

  const props = defineProps({
    isClickable: {
      type: Boolean,
      default: true,
      required: false
    },
    isNewbie: {
      type: Boolean,
      default: false,
      required: false
    },
    rankingPositions: {
      type: Object,
      default: () => ({}),
      required: true
    },
    userId: {
      type: [String, Number],
      default: null,
      required: false
    }
  })

  provide('isClickable', props.isClickable)
  const activeTabWithTimeframe = inject('activeTabWithTimeframe', () => null)

  const rankingEntry = computed(() => {
    if (!isObject(props.rankingPositions)) {
      return
    }

    // Show selected tab's ranking entry
    if (activeTabWithTimeframe && activeTabWithTimeframe.value) {
      const [who, timeframe] = activeTabWithTimeframe.value.split('_')
      const rankingPosition = props.rankingPositions[activeTabWithTimeframe.value]

      return {
        who: who,
        timeframe: timeframe,
        rank: rankingPosition.rank,
        score: rankingPosition.score
      }
    }

    // Show the highest ranking entry
    const maxRank = Object.entries(props.rankingPositions).reduce((max, current) => {
      return current[1].rank < max[1].rank ? current : max
    })

    return {
      who: maxRank[0].split('_')[0],
      timeframe: maxRank[0].split('_')[1],
      rank: maxRank[1].rank,
      score: maxRank[1].score
    }
  })

  function handleClick () {
    if (props.userId && props.isClickable) {
      clientEventTrack({ typeName: 'highest_rank_badge_clicked', userId: props.userId })
    }
  }
</script>

<template v-if="authStore.authOptions?.rankings">
  <RankingNewbieBadge v-if="isNewbie"/>

  <RankingCreatorsBadge
    v-else-if="rankingEntry && rankingEntry.who === 'creators'"
    :rank="rankingEntry.rank"
    :timeframe="rankingEntry.timeframe"
    @click="handleClick"/>

  <RankingFansBadge
    v-else-if="rankingEntry && rankingEntry.who === 'consumers'"
    :rank="rankingEntry.rank"
    :timeframe="rankingEntry.timeframe"/>
</template>