<template>
  <div class="px-4">
    <div class="flex items-center justify-between w-full mt-5">
      <div class="flex items-center w-full">
        <h3 class="flex-grow w-full max-w-max text-lg font-bold whitespace-nowrap relative text-ellipsis overflow-hidden">
          {{ creator.name }}
        </h3>

        <!-- Online/Offline-->
        <template v-if="options.prelandingId === 'apreland'">
          <div 
            v-if="!secretToken"
            class="mx-1.5">
            <SimpleTooltip
              v-if="creator.online"
              text="User has been detected active for the last 15 min">
              <div class="flex items-center bg-[#2CCF81] text-caption text-white rounded px-1 py-0.5">
                AI online

                <div class="ml-1 text-[9px] leading-[15px] rounded bg-white w-max px-[3px] text-[#2CCF81] font-bold">
                  AI
                </div>
              </div>
            </SimpleTooltip>

            <div
              v-else
              class="flex items-center justify-center whitespace-nowrap">
              <div class="flex items-center bg-red-400 text-caption text-white rounded px-1 py-0.5">
                AI offline

                <div class="ml-1 text-[9px] leading-[15px] rounded bg-white w-max px-[3px] text-red-400 font-bold">
                  AI
                </div>
              </div>
            </div>
          </div>
        </template>
        
        <template v-else>
          <div
            v-if="!secretToken"
            class="mx-1.5">
            <SimpleTooltip
              v-if="creator.online"
              text="User has been detected active for the last 15 min">
              <div class="flex items-center bg-[#2acf81] text-caption text-white rounded px-1 py-0.5">
                online

                <InlineSvg
                  class="inline-block shrink-0 w-3.5 ml-0.5"
                  :src="require('@/assets/svg/design/i_mobile.svg')"/>
              </div>
            </SimpleTooltip>

            <div
              v-else
              class="flex items-center justify-center whitespace-nowrap">
              <span class="text-caption text-red-400">
                offline
              </span>

              <span class="ml-1 flex-shrink-0 inline-block h-2 w-2 rounded-full bg-red-400"/>
            </div>
          </div>
        </template>
     
        <div class="flex items-center flex-grow justify-end gap-1.5">
          <TipsModal
            v-if="secretToken && options.tipsEnabled && creator?.username"
            :user="creator"/>

          <!-- Verified -->
          <button
            v-if="options.verificationEnabled && (creator.tikTokFollowerCount || creator.instagramFollowerCount)"
            class="group flex items-center px-1.5 py-1 rounded bg-gray-100 hover:text-white hover:bg-[#6fbeef]"
            type="button"
            @click="$refs.verifiedModal.openModal();clientEventTrack({ typeName: 'verification__popup_shown' })">
            <InlineSvg
              :src="require('@/assets/svg/design/verified.svg')"
              class="mr-1 shrink-0 group-hover:hidden"/>

            <InlineSvg
              :src="require('@/assets/svg/design/verified-hovered.svg')"
              class="mr-1 shrink-0 hidden group-hover:inline-block"/>

            Verified

            <InlineSvg
              :src="require('@/assets/svg/design/info.svg')"
              class="ml-1 w-4 shrink-0 text-[#b9babc] group-hover:text-[#b8dff8]"/>
          </button>
        </div>
      </div>
    </div>

    <RatingsBadge
      v-if="creator.rankingPositions"
      class="whitespace-nowrap max-w-max"
      :user-id="creator.userId"
      :ranking-positions="creator.rankingPositions"/>

    <p
      v-if="creator.about"
      class="mt-2 mb-1 text-caption text-gray-600">
      {{ creator.about }}
    </p>

    <div class="flex justify-between items-center gap-2">
      <template v-if="creator.tikTokFollowerCount || creator.instagramFollowerCount">
        <template v-if="creator.tikTokFollowerCount && creator.tikTokUsername">
          <div
            v-if="creator.tikTokFollowerCount"
            class="flex items-center mt-3 mb-4 w-full">
            <div class="flex items-center rounded-lg bg-[#f6f8fa] py-1 pl-1 pr-1.5 mr-2">
              <InlineSvg
                class="inline-block w-5 h-5 mr-0.5 text-gray-500"
                :src="require('@/assets/svg/design/tiktok2.svg')"/>

              <a
                target="_blank"
                :title="creator.tikTokUsername"
                :href="`https://tiktok.com/${getTikTokUsername()}`"
                class="text-sm text-gray-700">
                @{{ creator.tikTokUsername }}
              </a>
            </div>

            <div
              v-if="creator.tikTokFollowerCount"
              class="flex items-center  py-1 pl-1 pr-1.5 mr-2">
              <div class="flex items-center text-sm text-gray-700 font-semibold leading-4">
                <InlineSvg
                  class="inline-block mr-1 w-5 h-5 p-0.5 text-gray-500"
                  :src="require('@/assets/svg/design/user.svg')"/>

                {{ countNumberFormat(creator.tikTokFollowerCount) }}
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="creator.instagramUsername && creator.instagramFollowerCount">
          <div
            v-if="creator.instagramFollowerCount"
            class="flex items-center mt-3 mb-4 w-full">
            <div class="flex items-center rounded-lg bg-[#f6f8fa] py-1 pl-1 pr-1.5 mr-2">
              <InlineSvg
                class="inline-block w-4 ml-0.5 mr-1  text-gray-500"
                :src="require('@/assets/svg/design/instagram.svg')"/>

              <a
                target="_blank"
                :title="creator.instagramUsername"
                :href="`https://www.instagram.com/${creator.instagramUsername}/`"
                class="text-sm text-gray-700">
                {{ creator.instagramUsername }}
              </a>
            </div>

            <div
              v-if="creator.instagramFollowerCount"
              class="flex items-center  py-1 pl-1 pr-1.5 mr-2">
              <div class="flex items-center text-sm text-gray-700 font-semibold leading-4">
                <InlineSvg
                  class="inline-block mr-1 w-5 h-5 p-0.5 text-gray-500"
                  :src="require('@/assets/svg/design/user.svg')"/>

                {{ countNumberFormat(creator.instagramFollowerCount) }}
              </div>
            </div>
          </div>
        </template>
      </template>

      <div
        v-else
        class="flex items-center mt-3 mb-4 w-full">
        <div class="flex items-center rounded-lg bg-[#f6f8fa] p-2 mr-2">
          <span class="text-sm font-semibold mr-1 leading-4">
            {{ countNumberFormat(creator.postViewCount) }}
          </span>

          <span class="text-caption text-gray-400">
            {{ $tc('creator.profile.views', creator.postViewCount) }}
          </span>
        </div>

        <div class="flex items-center rounded-lg bg-[#f6f8fa] p-2">
          <span class="text-sm font-semibold mr-1 leading-4">
            {{ countNumberFormat(creator.postLikeCount) }}
          </span>

          <span class="text-caption text-gray-400">
            {{ $tc('creator.profile.likes', creator.postLikeCount) }}
          </span>
        </div>

        <template v-if="creator.tikTokUsername">
          <div class="p-2 mr-2 overflow-hidden">
            <div
              v-if="creator.tikTokUsername"
              class="block truncate">
              <InlineSvg
                class="inline-block w-5 h-5 mr-0.5"
                :src="require('@/assets/svg/design/tiktok2.svg')"/>

              <a
                target="_blank"
                :title="creator.tikTokUsername"
                :href="`https://tiktok.com/${getTikTokUsername()}`"
                class="text-sm text-gray-700">
                @{{ creator.tikTokUsername }}
              </a>
            </div>
          </div>
        </template>

        <template v-else-if="creator.instagramUsername">
          <div class="p-2 mr-2 overflow-hidden">
            <div
              v-if="creator.instagramUsername"
              class="block truncate">
              <InlineSvg
                class="inline-block w-4 h-4 mr-0.5"
                :src="require('@/assets/svg/design/instagram.svg')"/>

              <a
                target="_blank"
                :title="creator.instagramUsername"
                :href="`https://www.instagram.com/${creator.instagramUsername}/`"
                class="text-sm text-gray-700">
                {{ creator.instagramUsername }}
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      :class="{
        'mb-4': !creator.contact && creator.online
      }"
      class="flex w-full mt-4 space-x-2">
      <template v-if="isAuthenticated">
        <div class="w-full flex justify-between items-center">
          <div class="flex-grow w-full flex items-center gap-2">
            <router-link
              v-if="creator.subscribed || !authOptions.chatRequiresSubscription"
              class="flex items-center btn py-2 btn-blue-ghost whitespace-nowrap cursor-pointer w-full relative stroke-[#4756DA] hover:stroke-white"
              :to="`/chat/${creator.userId}`"
              @click="messageMeClicked(creator.userId, 'profile')">
              <template v-if="options.prelandingId === 'apreland'">
                Chat with AI assistant
              </template>

              <template v-else>
                {{ $t('chat.message.message_me') }}
              </template>

              <InlineSvg
                class="inline-block w-4 h-4 text-transparent ml-1.5"
                :src="require('@/assets/svg/design/send.svg')"/>

              <span
                v-if="creator.unreadIncomingMessageCount || showBadgeAfterSubscription"
                class="inline-flex leading-[1.2rem] items-center px-2 py-0.5 rounded-full text-xs font-mono font-medium bg-red-400 text-white absolute -top-2 -right-2">
                {{ creator.unreadIncomingMessageCount ? creator.unreadIncomingMessageCount : '1' }}
              </span>
            </router-link>

            <button
              v-else-if="!secretToken"
              class="relative flex items-center btn py-2 btn-blue-ghost whitespace-nowrap w-full stroke-[#4756DA] hover:stroke-white"
              @click="$refs.subscribeModal.openModal(); messageMeClicked(creator.userId, 'profile')">
              <template v-if="options.prelandingId === 'apreland'">
                Chat with AI assistant
              </template>

              <template v-else>
                {{ $t('chat.message.message_me') }}
              </template>

              <InlineSvg
                class="inline-block w-4 h-4 text-transparent ml-1.5"
                :src="require('@/assets/svg/design/send.svg')"/>

              <span
                v-if="creator.unreadIncomingMessageCount"
                class="inline-flex leading-[1.2rem] items-center px-2 py-0.5 rounded-full text-xs font-mono font-medium bg-red-400 text-white absolute -top-2 -right-2">
                {{ creator.unreadIncomingMessageCount }}
              </span>
            </button>

            <CretorInfoMoreContentButton 
              v-if="options.moreContentEnabled"
              :user-id="creator.userId"/>
          </div>

          <TipsModal
            v-if="options.tipsEnabled && !secretToken && creator?.username"
            :user="creator"
            class="ml-2"/>
        </div>
      </template>

      <template v-else-if="!secretToken">
        <button
          type="button"
          class="flex-grow btn btn-blue-ghost stroke-[#4756DA] hover:stroke-white whitespace-nowrap cursor-pointer w-full sm:w-auto"
          @click="showOnbordingOrSignUp(); pathToRedirect = `/@${creator.username}`">
          {{ $t('chat.message.message_me') }}

          <InlineSvg
            class="inline-block w-4 h-4 text-transparent ml-1.5"
            :src="require('@/assets/svg/design/lock-2.svg')"/>
        </button>
      </template>
    </div>
  </div>

  <Modal
    ref="subscribeModal"
    v-slot="{ closeModal, isVisible }"
    name="Subscribe"
    position="bottom"
    rounded-class="rounded-t-2.5xl"
    overflow-class="overflow-y-auto"
    content-class="bg-white px-6 pt-5 pb-4"
    :has-indents="false">
    <SubscribeContent
      :user="creator"
      :is-visible="isVisible"
      :close="closeModal"
      @subscribed="subscribed"/>
  </Modal>

  <Modal
    v-slot="{ closeModal }"
    ref="verifiedModal"
    name="Verified modal"
    position="bottom"
    overflow-class="overflow-y-auto"
    content-class="bg-white"
    wrapper-class=""
    rounded-class="rounded-t-2.5xl"
    :show-close-cross="false"
    :has-indents="false">
    <InlineSvg
      :src="require('@/assets/svg/design/cross.svg')"
      class="text-gray-900 absolute top-0 right-0 p-1 w-5 h-5 mt-6 mr-5 cursor-pointer"
      @click="closeModal"/>

    <VerifiedContent
      :social-name="((creator.tikTokUsername && creator.tikTokFollowerCount) || (creator.tikTokUsername && !creator.instagramFollowerCount)) ? 'TikTok' : 'Instagram'"
      @close="closeModal"/>
  </modal>
</template>

<script>
import Modal from '@/components/Functional/Modal'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useCreatorStore } from '@/stores/creator'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { random } from 'lodash'
import helpers from '@/helpers'
import TipsModal from '@/components/TipsModal'
import VerifiedContent from '@/components/ModalContents/VerifiedContent.vue'
import { clientEventTrack } from '@/helpers/methods'
import SimpleTooltip from '@/components/Functional/SimpleTooltip.vue'
import CretorInfoMoreContentButton from '@/components/Creator/CreatorInfo/CretorInfoMoreContentButton.vue'
import RatingsBadge from '@/components/Ranking/Common/RankingBadge.vue'

export default {
  name: 'CreatorInfo',
  components: {
    Modal,
    SubscribeContent,
    TipsModal,
    VerifiedContent,
    SimpleTooltip,
    CretorInfoMoreContentButton,
    RatingsBadge
  },
  data () {
    return {
      shareData: {}
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isiOS',
      'secretToken'
    ]),
    ...mapState(useUserStore, [
      'userId',
      'features'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isTokenExist',
      'options',
      'authOptions'
    ]),
    ...mapState(useSubscriptionsStore, [
      'recentSubscriptions'
    ]),
    ...mapWritableState(useCreatorStore, [
      'creator',
      'respondsTime',
      'likes'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible',
      'pathToRedirect'
    ]),
    showBadgeAfterSubscription () {
      return this.recentSubscriptions.includes(this.creator.userId)
    }
  },
  beforeMount () {
    if (!this.respondsTime[this.creator.username]) {
      this.respondsTime[this.creator.username] = random(5, 15)
    }
  },
  mounted () {
    this.shareData = {
      title: 'Profile on One2Fan',
      text: 'Have a look at the profile on One2Fan',
      url: `https://one2fan.com/@${this.creator.username}`
    }
  },
  methods: {
    clientEventTrack: clientEventTrack,
    showLogIn () {
      helpers.showLogIn()
    },
    showOnbordingOrSignUp () {
      helpers.showOnbordingOrSignUp()
    },
    ...mapActions(useCreatorStore, [
      'userShared'
    ]),
    getTikTokUsername () {
      return this.creator.tikTokUsername.charAt(0) === '@' ? this.creator.tikTokUsername : `@${this.creator.tikTokUsername}`
    },
    async subscribed () {
      await this.$router.push(`/chat/${this.creator.userId}`)
    },
    async like () {
      if (!this.isTokenExist) {
        helpers.showSignUp()
      }

      this.likes[this.creator.userId] = !this.likes[this.creator.userId]
    },
    async messageMeClicked (userId, clickSource) {
      const res = await this.wsp.sendRequest({
        data: {
          userId,
          clickSource
        },
        method: 'messageMe.clicked'
      })

      console.log(res)
    }
  }
}
</script>

<style scoped>
  .border-right {
    position: relative;
  }
  .border-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 0.5rem);
    height: 1rem;
    width: 1px;
    background: #cccccc;
  }
</style>
