<script setup>
  import { inject, computed } from 'vue'

  const isClickable = inject('isClickable')

  const component = computed(() => {
    return isClickable.value ? 'router-link' : 'span'
  })
</script>

<template>
  <component
    :is="component"
    :to="{ path: '/ranking', query: { tab: 'consumers_30d' }}"
    class="whitespace-nowrap flex items-center gap-1 w-max bg-green-500 text-white pl-0.5 pr-1.5 text-[9px] leading-3 font-bold rounded-full border border-green-200">
    <span class="relative">
      <InlineSvg
        class="w-2.5 h-2.5 rotate-45"
        :src="require('@/assets/fontawesome/sharp-solid/square.svg')"/>
        
      <InlineSvg
        class="w-1 h-1 rotate-45 text-green-500 absolute inset-0 m-auto"
        :src="require('@/assets/fontawesome/sharp-solid/square.svg')"/>
    </span>

    Newbie
  </component>
</template>