import app from '@/main.js'

const clientEventTrack = async (data) => {
  return app.wsp.sendRequest({
    data: data,
    method: 'clientEvent.track'
  })
}

function getUser (data) {
  return app.wsp.sendRequest({
    data: data,
    method: 'user.get'
  })
}

export {
  clientEventTrack,
  getUser
}
