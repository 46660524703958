<script setup>
</script>

<template>
  <div class="flex items-end gap-x-4">
    <div class="flex flex-col items-center">
      <div class="mx-1 w-18 h-18 skeleton-loading rounded-full"/>

      <div class="h-[22px] w-[84px] py-0.5 mt-2 rounded-full skeleton-loading"/>
    </div>

    <div class="flex flex-col items-center">
      <div class="mx-1 w-24 h-24 skeleton-loading rounded-full"/>

      <div class="h-[22px] w-[84px] py-0.5 mt-2 rounded-full skeleton-loading"/>
    </div>

    <div class="flex flex-col items-center">
      <div class="mx-1 w-18 h-18 skeleton-loading rounded-full"/>

      <div class="h-[22px] w-[84px] py-0.5 mt-2 rounded-full skeleton-loading"/>
    </div>
  </div>
</template>