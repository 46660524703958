<script setup>
  import { computed, ref, onMounted } from 'vue'

  const props = defineProps({
    user: {
      type: Object,
      default: () => ({
        name: null,
        sex: null,
        age: null,
        photos: {
          items: []
        }
      }),
      required: true
    }
  })

  const loadState = ref('loading')

  const avatarImage = computed(() => {
    const photos = props.user?.photos.items

    return photos && photos.length >= 1 ? photos[0].links.c320x320 : null
  })

  onMounted(() => {
    if (!avatarImage.value) {
      loadState.value = 'loaded'
    }
  })
</script>

<template>
  <div class="block relative">
    <span
      v-show="loadState === 'loading'"
      class="absolute inset-0 !border-2 !border-b-gray-500 w-full h-full loader"/>

    <img
      v-if="avatarImage"
      :class="[loadState === 'loaded' ? 'visible' : 'invisible']"
      class="rounded-full w-full h-full text-transparent object-cover"
      :src="avatarImage"
      :alt="user.name ? user.name.trim() + '\'s photo' : `User's photo`"
      @error="loadState = 'error'"
      @load="loadState = 'loaded'">

    <img
      v-else
      :src="`/svg/${user?.sex === 1 ? 'user' : 'woman'}-placeholder.svg`"
      class="rounded-full bg-white w-full"
      :title="user?.name && user.name.trim()"
      :alt="`${user?.sex === 1 ? 'Man' : 'Woman'}`">
  </div>
</template>
